// @ts-check

import _ from 'lodash'
import Vue from 'vue'
import VueInstance from '@/main'
import IncExc from './inc-exc'
import Debug, { debugMemoryCleanUp } from '@/debugger'

let filterParamId = 0

/**
 * @typedef {import('./search').DocumentId} DocumentId
 * @typedef {import('./column').default} Column
 * @typedef {{id: DocumentId["type"]}} Category
 * @typedef {'bw'|'c'|'empty'|'ew'|'='|'>'|'>='|'<'|'<='|'sw'} Operator
 * @typedef {'empty'|'bool'|'date'|'document'|'number'|'string'} ValueType
 */

/**
 * @class
 */
export default class FilterParam {
  /** @type {Array.<Condition>} */
  #conds = []
  /** @type {(boolean|undefined)} */
  #consolidated = undefined
  #filterParamId = filterParamId++
  /** @type {Map.<DocumentId["type"], IncExc<DocumentId["id"]>>} */
  #selectedValues = new Map()

  /**
   * @param {{
   * consolidated: boolean,
   * conds: Array.<Condition>
   * & Object.<string, [
   *  Array.<DocumentId["id"]>,
   *  Array.<DocumentId["id"]>
   * ]> }} filterParam
   */
  constructor(filterParam) {
    debugMemoryCleanUp(this, `filter-param ${this.#filterParamId}`)
    Debug(`cot:filter-param:${this.#filterParamId}`)('New filter-param created with id %d', this.#filterParamId)
    if (!_.isEmpty(filterParam)) {
      this.#consolidated = filterParam.consolidated
      this.#conds = (filterParam.conds ?? []).map(cond => new Condition(cond))
      this.#selectedValues = new Map(
        Object.entries(filterParam)
          .filter(([type, _]) => !['conds', 'consolidated'].includes(type))
          // @ts-ignore Because I can't ficure why I can't force the map parameter type
          .map(([type, [inc, exc]]) => ([type, new IncExc(true).include(inc).exclude(exc)]))
      )
    }
  }

  /**
   * @private
   * @param {Category} category
   */
  _displayValueByCategory (category) {
    const incExc = this.#selectedValues.get(category?.id)
    if (incExc) {
      const type = category?.id
      const inc = incExc.includeItems
      const exc = incExc.excludeItems
      if (inc.length) {
        return { type, id: inc[0], inc: 1 }
      } else {
        return { type, id: exc[0], inc: 0 }
      }
    }
  }

  /**
  * @private
  */
  _diplayFirstValue () {
    const items = [...this.#selectedValues]
    if (items.length) {
      const t = items[0]
      const type = t[0]
      const incExc = t[1].toJSON()
      const inc = incExc[0]
      const exc = incExc[1]
      if (inc.length) {
        return { type, id: inc[0], inc: 1 }
      } else {
        return { type, id: exc[0], inc: 0 }
      }
    }
  }

  /**
   * Add a condition
   * @param {Column} col
   * @param {Operator}  [ops='c']
   * @param {ValueType} [type='string']
   * @returns {this}
   */
  addCondition (col, ops = 'c', type = 'string') {
    this.#conds.push(new Condition(
      {
        col: col.id,
        currency: col.currency,
        consolidated: col.consolidated,
        ops,
        comparand: { c: [], valTy: type }
      }
    ))
    return this
  }

  /**
   * Add a condition value
   * @param {Condition} condition
   * @param {string} value
   * @returns {this}
   */
  addConditionValue (condition, value) {
    const cond = this.#conds.find(c => c === condition)
    if (cond) {
      cond.comparand.c.push(value)
    }
    return this
  }

  /**
   * Clear all conditions values
   * @returns {this}
   */
  clearAllConditionValues () {
    this.#conds.forEach(cond => this.clearCondition(cond))
    return this
  }

  /**
   * Clear a condition values
   * @param {Condition} condition
   * @returns {this}
   */
  clearCondition (condition) {
    const cond = this.#conds.find(c => c === condition)
    if (cond) {
      cond.comparand.c = []
    }
    return this
  }

  /**
   * Clear selected values
   * @param {Category} category
   * @returns {this}
   */
  clearSelectedValues (category) {
    if (category?.id) {
      this.#selectedValues.get(category?.id)?.clear()
    } else {
      this.#selectedValues = new Map()
    }
    return this
  }

  /**
   * Display the value of a filter
   * @param {Category} category
   */
  displayValue (category) {
    return category?.id ? this._displayValueByCategory(category) : this._diplayFirstValue()
  }

  /**
   * @param {DocumentId} id
   * @param {boolean} inc
   * @returns {Promise<Object>}
   */
  async getFilterParamsFromSearch (id, inc) {
    const request = await VueInstance.$http()
      .post('/core/v6/search/filter-inc-exc', { filters: this.toJSON(), id, inc })
    return request?.data.filters
  }

  /**
   * Return true if the given column is already in conditions
   * @param {DocumentId["id"]} columnId
   * @param {string} [currency]
   * @param {boolean} [consolidated]
   * @returns {boolean}
   */
  hasConditionForColumn (columnId, currency, consolidated) {
    return this.#conds.some(c => c.col === columnId && c.currency === (currency === 'system' ? undefined : currency) && c.consolidated === (consolidated ?? true))
  }

  /**
   * @param {DocumentId} item
   * @returns {number}
   */
  incExcSwitchValue (item) {
    item.id = item.id ?? null
    const incExc = this.#selectedValues.get(item.type)
    return incExc?.isIncluded(item.id) ? 1 : (incExc?.isExcluded(item.id) ? 0 : NaN)
  }

  /**
   * Remove a condition
   * @param {Condition} condition
   * @returns {this}
   */
  removeCondition (condition) {
    const index = this.#conds.findIndex(c => c === condition)
    if (index > -1) {
      this.#conds.splice(index, 1)
    }
    return this
  }

  /**
   * Remove a condition value by index
   * @param {DocumentId["id"]} columnId
   * @param {number} index
   * @returns {this}
   */
  removeConditionValue (columnId, index) {
    this.#conds[columnId].comparand.c.splice(index, 1)
    return this
  }

  /**
   * @param {Category} category
   * @returns {Array.<DocumentId>}
   */
  selectedIds (category) {
    if (category?.id) {
      const item = this.#selectedValues.get(category?.id)
      return item ? [item].map(incExc => incExc?.toJSON()?.flat().map(id => /** @type {DocumentId} */ ({ type: category?.id, id }))).flat() : []
    } else {
      return [...this.#selectedValues].map(([type, incExc]) => incExc?.toJSON()?.flat().map(id => /** @type {DocumentId} */ ({ type, id }))).flat()
    }
  }

  /**
   * @param {boolean} v
   */
  setConsolidated (v) {
    this.#consolidated = v
  }

  /**
   * @param {DocumentId} item
   * @param {{key: {shiftKey: boolean, ctrlKey:boolean}, value: 0|1|NaN}} incExcSwitchValue
   * @param {boolean} [clearSelectedValues=true]
   */
  async updateSelectedValues (item, incExcSwitchValue, clearSelectedValues = true) {
    item.id = item.id ?? null
    if (clearSelectedValues && !incExcSwitchValue.key.shiftKey && !incExcSwitchValue.key.ctrlKey && !isNaN(incExcSwitchValue.value)) {
      this.clearSelectedValues({ id: item.type })
      this.#selectedValues.set(item.type, new IncExc().include([item.id]))
    } else {
      switch (incExcSwitchValue.value) {
        case 0:
        case 1: {
          const filter = await this.getFilterParamsFromSearch({ id: item.id, type: item.type }, Boolean(incExcSwitchValue.value))
          this.#selectedValues = new Map(
            Object.entries(filter)
              .filter(([type, _]) => !['conds', 'consolidated'].includes(type))
              .map(([type, incExc]) => ([type, new IncExc(true).include(incExc[0]).exclude(incExc[1])]))
          )
          break
        }
        default: {
          const incExc = this.#selectedValues.get(item.type) ?? new IncExc()

          if (incExc.isIncluded(item.id)) {
            incExc.removeInclude([item.id])
          } else if (incExc.isExcluded(item.id)) {
            incExc.removeExclude([item.id])
          }

          if (incExc.isEmpty()) {
            this.#selectedValues.delete(item.type)
          } else {
            this.#selectedValues.set(item.type, incExc)
          }
        }
      }
    }
  }

  toJSON () {
    const obj = {}
    const types = Object.fromEntries(this.#selectedValues.entries())
    if (Object.keys(types).length) {
      Object.assign(obj, types)
    }
    if (typeof this.#consolidated === 'boolean') {
      Object.assign(obj, { consolidated: this.#consolidated })
    }

    if (this.#conds.length) {
      Object.assign(obj, { conds: this.#conds })
    }

    return _.isEmpty(obj) ? undefined : obj
  }

  /**
   * @returns {Map.<DocumentId["id"], IncExc>}
   */
  get selectedValues () {
    return this.#selectedValues
  }

  /**
   * @returns {boolean}
   */
  get consolidated () {
    return this.#consolidated
  }

  /**
   * @returns {Array.<Condition>}
   */
  get conds () { return this.#conds }

  /**
   * @returns {Object.<DocumentId["id"], Condition>}
   */
  get groupedConds () { return _.groupBy(this.#conds, v => [v.col, v.currency, v.consolidated]) }

  get hasActiveFilter () {
    return this.selectedValues.size || this.conds.some(c => c.isActive)
  }
}

/**
* @typedef {{
*   col: DocumentId["id"],
*   currency: string,
*  comparand: {
*    c: Array.<string>,
*    valTy: ValueType
*  },
*  consolidated: boolean,
*  ops: Operator
* }} RawCondition
*/

let conditionId = 0

export class Condition {
  #conditionId = conditionId++
  #reactiveData = Vue.observable({
    /** @type {DocumentId["id"]} */
    col: undefined,
    currency: undefined,
    comparand: {
      /** @type {Array.<string>} */
      c: [],
      /** @type {ValueType} */
      valTy: undefined
    },
    /** @type {boolean}  */
    consolidated: undefined,
    /** @type {Operator} */
    ops: undefined
  })

  /**
   * @param {RawCondition} condition
   */
  constructor(condition) {
    debugMemoryCleanUp(this, `condition ${this.#conditionId}`)
    Debug(`cot:condition:${this.#conditionId}`)('New condition created with id %d', this.#conditionId)
    this.#reactiveData.col = condition.col
    this.#reactiveData.currency = condition.currency ?? undefined
    this.#reactiveData.comparand = {
      c: condition.comparand.c,
      valTy: condition.comparand.valTy
    }
    this.#reactiveData.consolidated = condition.consolidated ?? true
    this.#reactiveData.ops = condition.ops
  }

  /**
   * @param {string} value
   */
  addValue (value) {
    this.#reactiveData.comparand.c.push(value)
  }

  clearValues () {
    this.#reactiveData.comparand.c = []
  }

  displayText (currency) {
    switch (this.comparand.valTy) {
      case 'string':
        return this.comparand.c.filter(c => c?.length).join(' ').slice(0, 200)
      case 'date': {
        const [dateFrom, dateTo] = this.comparand.c
        let display = ''
        if (dateFrom || dateTo) {
          // @ts-ignore
          display = `${dateFrom ? VueInstance.formatDate(dateFrom) : '…'} - ${dateTo ? VueInstance.formatDate(dateTo) : '…'}`
        }
        return display
      }
      case 'number': {
        let [amountFrom, amountTo] = this.comparand.c
        if (currency) {
          // @ts-ignore
          amountFrom = amountFrom !== null ? VueInstance.formatCurrency(amountFrom, currency) : null
          // @ts-ignore
          amountTo = amountTo !== null ? VueInstance.formatCurrency(amountTo, currency) : null
        }
        let display = ''
        if (amountFrom !== null || amountTo !== null) {
          display = `${amountFrom ?? '…'} - ${amountTo ?? '…'}`
        }
        return display
      }
    }
  }

  /**
   * @param {number} index
   * @param {string} value
   */
  setValue (index, value) {
    this.#reactiveData.comparand.c[index] = value
  }

  /**
   * @param {Array.<string>} values
   */
  setValues (values = []) {
    Vue.set(this.#reactiveData.comparand, 'c', values)
  }

  toJSON () {
    return {
      col: this.#reactiveData.col,
      currency: this.#reactiveData.currency === 'system' ? undefined : this.#reactiveData.currency,
      comparand: this.#reactiveData.comparand,
      consolidated: this.#reactiveData.consolidated,
      ops: this.#reactiveData.ops
    }
  }

  get col () { return this.#reactiveData.col }
  get currency () { return this.#reactiveData.currency }
  get comparand () { return this.#reactiveData.comparand }
  get consolidated () { return this.#reactiveData.consolidated }
  get ops () { return this.#reactiveData.ops }

  get isActive () {
    return this.comparand.c?.filter(v => !_.isNil(v) && (!_.isString(v) || v.length)).length
  }
}
