// @ts-check

/**
 * @class
 */
export default class Scope {
  #scope
  #scopeType

  /**
   * @param {Object} scope
   * @param {ScopeType} scopeType
   */
  constructor(scope, scopeType) {
    this.#scope = scope
    this.#scopeType = scopeType
  }

  /**
   * @param {string} accountId AccountId
   * @returns {Scope}
   */
  static account (accountId) {
    return new Scope({ accountId }, Scope.ACCOUNT)
  }

  /**
   * @param {string} allocationAnnouncementId AllocationAnnouncementId
   * @returns {Scope}
   */
  static allocationAnnoucement (allocationAnnouncementId) {
    return new Scope({ allocationAnnouncementId }, Scope.ALLOCATION_ANNOUCEMENT)
  }

  /**
   * @param {string} disputeId DisputeId
   * @param {string} disputeInvoicesAccountId AccountId
   * @returns {Scope}
   */
  static disputeInvoicesAccount (disputeId, disputeInvoicesAccountId) {
    return new Scope({ disputeId, disputeInvoicesAccountId }, Scope.DISPUTE_INVOICES_ACCOUNT)
  }

  /**
   * @param {string} disputeResolversAccountId AccountId
   * @returns {Scope}
   */
  static disputeResolversAccount (disputeResolversAccountId) {
    return new Scope({ disputeResolversAccountId }, Scope.DISPUTE_RESOLVERS_ACCOUNT)
  }

  /**
   * @returns {Scope}
   */
  static global () {
    return new Scope('global', Scope.GLOBAL)
  }

  /**
   * @param {string} promiseId PromiseId
   * @param {string} promiseInvoicesAccountId AccountId
   * @returns {Scope}
   */
  static promiseInvoicesAccount (promiseId, promiseInvoicesAccountId) {
    return new Scope({ promiseId, promiseInvoicesAccountId }, Scope.PROMISE_INVOICES_ACCOUNT)
  }

  /**
   * @param {string} userId UserId
   * @returns {Scope}
   */
  static user (userId) {
    return new Scope({ userId }, Scope.USER)
  }

  toJSON () {
    return this.#scope
  }

  get accountId () {
    return this.#scope.accountId ??
      this.#scope.disputeInvoicesAccountId ??
      this.#scope.disputeResolversAccountId ??
      this.#scope.promiseInvoicesAccountId
  }

  get type () {
    return this.#scopeType
  }

  /**
   * @typedef {'account'|'allocationAnnoucement'|'disputeInvoicesAccount'|'disputeResolversAccount'|
   * 'global'|'promiseInvoicesAccount'|'user'} ScopeType
   * */
  /** @type {ScopeType} */
  static ACCOUNT = 'account'
  /** @type {ScopeType} */
  static ALLOCATION_ANNOUCEMENT = 'allocationAnnoucement'
  /** @type {ScopeType} */
  static DISPUTE_INVOICES_ACCOUNT = 'disputeInvoicesAccount'
  /** @type {ScopeType} */
  static DISPUTE_RESOLVERS_ACCOUNT = 'disputeResolversAccount'
  /** @type {ScopeType} */
  static GLOBAL = 'global'
  /** @type {ScopeType} */
  static PROMISE_INVOICES_ACCOUNT = 'promiseInvoicesAccount'
  /** @type {ScopeType} */
  static USER = 'user'
}
