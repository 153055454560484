import _ from 'lodash'
import Vue from 'vue'
/**
 * @class
 * @template T
 */
export default class IncExc {
  /** @type {boolean} */
  #dual

  #equalCustomizer

  #reactiveData = Vue.observable({
    /** @type {Array<T>} */
    include: [],
    /** @type {Array<T>} */
    exclude: []

  })

  constructor(isDual = false) {
    this.#dual = Boolean(isDual)
  }

  /**
   * Clear excluded and included values
   * @returns {this}
   */
  clear () {
    this.#reactiveData.exclude = []
    this.#reactiveData.include = []
    return this
  }

  /**
   * Add a list of values to exclude
   * @param {Array<T>} values
   * @returns {this}
   */
  exclude (values) {
    if (!this.#dual) {
      this.#reactiveData.include = []
    } else {
      this.removeInclude(values)
    }

    this.#reactiveData.exclude.push(..._.filter(values, v => !_.some(this.#reactiveData.exclude, i => _.isEqualWith(i, v, this.#equalCustomizer))))
    return this
  }

  /**
   * Add a list of values to include
   * @param {Array<T>} values
   * @returns {this}
   */
  include (values) {
    if (!this.#dual) {
      this.#reactiveData.exclude = []
    } else {
      this.removeExclude(values)
    }

    this.#reactiveData.include.push(..._.filter(values, v => !_.some(this.#reactiveData.include, i => _.isEqualWith(i, v, this.#equalCustomizer))))
    return this
  }

  /**
   * Return true if is empty
   * @returns {boolean}
   */
  isEmpty () {
    return !this.#reactiveData.exclude.length && !this.#reactiveData.include.length
  }

  /**
   * Return true if a value is an included value
   * @param {T} value
   * @returns {boolean}
   */
  isExcluded (value) {
    return _.some(this.#reactiveData.exclude, v => _.isEqualWith(value, v, this.#equalCustomizer))
  }

  /**
   * Return true if a value is an included value
   * @param {T} value
   * @returns {boolean}
   */
  isIncluded (value) {
    return _.some(this.#reactiveData.include, v => _.isEqualWith(value, v, this.#equalCustomizer))
  }

  /**
   * Refresh already included and excluded items with the given item list. Works only when a equalCustomizer is set.
   * Unknown items will be ignored
   * @param {Array.<T>} items
   * @returns {this}
   */
  refreshItems (items) {
    if (!this.#equalCustomizer) {
      if (process.env.NODE_ENV === 'development') {
        /** Dev checks will be removed during webpack's bundling */
        throw new Error('Items cannot be refresh when equalCustomizer is not set.')
      }
    } else {
      items.forEach(updated => {
        this.includeItems.forEach((item, index) => { if (_.isEqualWith(item, updated, this.#equalCustomizer)) { this.includeItems[index] = updated } })
        this.excludeItems.forEach((item, index) => { if (_.isEqualWith(item, updated, this.#equalCustomizer)) { this.excludeItems[index] = updated } })
      })
    }

    return this
  }

  /**
   * Remove a list of values from exclusion
   * @param {Array<T>} values
   */
  removeExclude (values) {
    this.#reactiveData.exclude = this.#reactiveData.exclude.filter(item => !values.some(v => _.isEqualWith(v, item, this.#equalCustomizer)))
  }

  /**
   * Remove a list of values from inclusion
   * @param {Array<T>} values
   */
  removeInclude (values) {
    this.#reactiveData.include = this.#reactiveData.include.filter(item => !values.some(v => _.isEqualWith(v, item, this.#equalCustomizer)))
  }

  /**
   * @callback EqualCustomizer
   * @param {T} objValue
   * @param {T} othValue
   * @returns {boolean}
   */

  /**
   * Set a customizer for equal comparison
   * @see https://lodash.com/docs/4.17.15#isEqualWith
   * @param {EqualCustomizer} fn
   * @returns {this}
   */
  setEqualCustomizer (fn) {
    if (this.size.total) {
      throw new Error('Attempting to set an equal customizer on a non-empty IncExc instance.')
    }
    this.#equalCustomizer = fn
    return this
  }

  toJSON () {
    if (this.#reactiveData.include.length || this.#reactiveData.exclude.length) {
      return [this.#reactiveData.include, this.#reactiveData.exclude]
    }
    // Return undefined if there is nothing to include or exclude
  }

  /**
   * Return a string describing the mode, can be either 'dual', 'exclude' or 'include'
   * @returns {'dual'|'exclude'|'include'}
   */
  get mode () {
    if (this.#dual) { return 'dual' }
    if (this.#reactiveData.exclude.length) { return 'exclude' }
    return 'include'
  }

  get size () {
    const exclude = this.#reactiveData.exclude.length
    const include = this.#reactiveData.include.length
    return {
      exclude,
      include,
      total: exclude + include
    }
  }

  /**
   * @return {boolean}
   */
  get dual () {
    return this.#dual
  }

  /**
   * @param {boolean} isDual
   */
  set dual (isDual) {
    this.#dual = isDual
  }

  get includeItems () {
    return this.#reactiveData.include
  }

  get excludeItems () {
    return this.#reactiveData.exclude
  }
}
